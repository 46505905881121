<template>
    <section class="referencias-view">

        <p class="referencias-view__title">
            <b>Reducing CO2 emissions</b>
        </p>

        <picture>
            <source srcset="https://d3jotkb1j9zbaa.cloudfront.net/referencias/Bayer_renewable_energy.webp" type="image/webp" />
            <img class="referencias-view__emmissions" loading="lazy" src="https://d3jotkb1j9zbaa.cloudfront.net/referencias/Bayer_renewable_energy.jpg" alt="Imágen del % de uso de energías renovables" title="Imágen del % de uso de energías renovables"/>
        </picture>

        <p class="referencias-view__content">
            Adiro® production cut CO2 emissions by 38.7% from just 2019 to the end of 2021. To retain global warming of 1.5 degrees in accordance to 
            the Paris Climate Agreement, we need to reduce our emissions annually by 4.2% until 2029. Our Adiro® production sites have already reduced 
            overall emissions by 38.7% from 2019 to the end of 2021 – over 40,000 tons. *At the end of 2021 compared to 2019.
        </p>

        <p class="referencias-view__content">
            Data source: BaySIS (= Bayer Site Information System; internal sustainability data base, integrated part of annual non-financial assurance) <br>
        </p>

        <table border>
            <tr>
                <th colspan="4">Total GHG</th>
                <th>Delta reduction</th>
                <th>Delta</th>
            </tr>

            <tr>
                <th>Site</th>
                <th>2019</th>
                <th>2020</th>
                <th>2021</th>
                <th>ABS</th>
                <th>%</th>
            </tr>

            <tr>
                <td>Site 1</td>
                <td>16.020</td>
                <td>15.379</td>
                <td>16.087</td>
                <td>67</td>
                <td>0</td>
            </tr>

            <tr>
                <td>Site 2</td>
                <td>21.722</td>
                <td>11.477</td>
                <td>5.388</td>
                <td>16.334</td>
                <td>75</td>
            </tr>

            <tr>
                <td>Site 3</td>
                <td>11.594</td>
                <td>3.431</td>
                <td>3.399</td>
                <td>8.195</td>
                <td>71</td>
            </tr>

            <tr>
                <td>Site 4</td>
                <td>14.930</td>
                <td>7.251</td>
                <td>8.261</td>
                <td>6.669</td>
                <td>45</td>
            </tr>

            <tr>
                <td>Site 5</td>
                <td>34.380</td>
                <td>28.169</td>
                <td>26.613</td>
                <td>7.767</td>
                <td>23</td>
            </tr>

            <tr>
                <td>Site 6</td>
                <td>7.966</td>
                <td>6.586</td>
                <td>5.577</td>
                <td>2.389</td>
                <td>30</td>
            </tr>

            <tr>
                <td><b>Total</b></td>
                <td><b>106.613</b></td>
                <td><b>72.294</b></td>
                <td><b>65.325</b></td>
                <td><b>41.288</b></td>
                <td><b>38.7</b></td>
            </tr>
        </table>

        <p>BaySIS data in tons CO2 eq</p>

        <p class="referencias-view__title">
            <b>Using more renewable energy</b>
        </p>
        <p class="referencias-view__content">
            What better way to help protect the environment, than by using the energy that it provides for us? So, we’ve been increasing 
            the amount of power we use from sources like hydro, solar and wind. This enabled Adiro® production sites to obtain 44.5% of 
            their energy from renewable sources by the end of 2021. Better still, by 2029 we aim to source 100% of our electricity from 
            those renewable sources. That’s a breath of fresh air.
        </p>

        <p class="referencias-view__content">
            Data source: BaySIS (= Bayer Site Information System; internal sustainability data base, integrated part of annual non-financial assurance) <br>
        </p>

        <table border>
            <tr>
                <th>Location</th>
                <th>Electricity consumption (TJ)</th>
                <th>Electricity from renewable sources (TJ)</th>
                <th>Indirect CO2 emissions (Tons)</th>
            </tr>

            <tr>
                <td>Site 1</td>
                <td>55</td>
                <td>-</td>
                <td>9.561</td>
            </tr>

            <tr>
                <td>Site 2</td>
                <td>52</td>
                <td>52</td>
                <td>3.692</td>
            </tr>

            <tr>
                <td>Site 3</td>
                <td>54</td>
                <td>54</td>
                <td>2.885</td>
            </tr>

            <tr>
                <td>Site 4</td>
                <td>146</td>
                <td>-</td>
                <td>11.879</td>
            </tr>

            <tr>
                <td>Site 5</td>
                <td>32</td>
                <td>-</td>
                <td>4.398</td>
            </tr>

            <tr>
                <td>Site 6</td>
                <td>81</td>
                <td>81</td>
                <td>7.531</td>
            </tr>

            <tr>
                <td><b>Total</b></td>
                <td><b>421</b></td>
                <td><b>187</b></td>
                <td><b>14.108</b></td>
            </tr>

            <tr>
                <td></td>
                <td></td>
                <td><b>44,5</b></td>
                <td><b>14.108</b></td>
            </tr>
        </table>

        <p class="referencias-view__title">
            <b>Optimising our packaging</b>
        </p>

        <picture>
            <source srcset="https://d3jotkb1j9zbaa.cloudfront.net/referencias/Bayer_materials.webp" type="image/webp" />
            <img class="referencias-view__materials" loading="lazy" src="https://d3jotkb1j9zbaa.cloudfront.net/referencias/Bayer_materials.jpg" alt="Imágen sobre la reduccion de materiales" title="Imágen sobre la reducción de materiales"/>
        </picture>

        <p class="referencias-view__content">
            Adiro® packaging materials reduced by ~48 tons annually. Our packs now use less carton, plastic, aluminium foil 
            and ink to be more friendly to nature. To achieve this we have: Optimised our Adiro® blisters to save 28 tons of 
            plastic and aluminium foil year- on-year Changed our folding box to reduce our annual paper board consumption by 7.4%, 
            which equals approximately 20 tons of paper Printed all patient leaflets in black and white only, so they use less treatment 
            and water in the recycling process.
        </p>

        <p class="referencias-view__title">
            <b>Data Source: Bayer internal</b>
        </p>

        <p class="referencias-view__title">
            <b>Blister Reduction</b>
        </p>

        <p class="referencias-view__content">
            Calculation of blister material reduction is based on the 36mm x 84 mm polypropylene-aluminium compound 
            blister, which is currently used in a 10 tablet variant and 14 tablet variant. Forecasted volumes for 2022 include an overall 
            amount of 97,05 mio blisters for the 10 tablet variant. This variant will be replaced by the 14 tablet variant, 
            keeping the blister dimensions as described above. This replacement will reduce the overall amount of required 14 tablet 
            blisters by 27,7 mio. Final volumes may vary. One 36 mm x 84 mm propylene-aluminium compound blister has an average 
            weight of 1,0 g. Therefore a reduction of 27,7 mio blisters is equal to a reduction of around 27,7 metric tons of blister 
            material, which is not needed for production anymore.
        </p>

        <p class="referencias-view__title">
            <b>Folding Boxes:</b>
        </p>

        <p class="referencias-view__content">
            Folding box adjustments contain two parts:  
        </p>

        <ul>
            <li>Adjustment of glue flap sizes</li> 
            <li>Reduction of paper grammage </li>
        </ul>  

        <p class="referencias-view__content">
            For 1): By reducing the size of the folding box’s glue flaps more blank boxes will print on a print sheet at the folding box 
            manufacturer. This effectively decreases the amount of waste within the folding box printing and cut-out process. This 
            change saves around 4,1% of paper board consumption or around 5 metric tons.
        </p> 

        <p class="referencias-view__content">
            For 2): By reducing the grammage for our 
            folding box formats from 240 g/m2 to 230 g/m2 while assuming a forecasted volume of around 2,4 mio paper board sheets in 
            2022, a reduction of around 5% of paper board consumption or 15 tons of paper board can be achieved.
        </p>

        <p class="referencias-view__title">
            <b>Leaflets:</b>
        </p>

        <p class="referencias-view__content">
            Discoloration of leaflets will be applied to a total of 51 individual leaflet SKUs that include more colours than black and white.
        </p>
    </section>
</template>

<script>
export default {
    name: 'ReferenciasView'
}
</script>